<template>
	<Confirmation :success="true" success-title="confirmation.kyc-documents-title" />
</template>

<script>
import { mapGetters } from 'vuex';
import Confirmation from '@/components/confirmation/Confirmation';

export default {
	name: 'KYCUploadConfirmation',
	components: {
		Confirmation,
	},
	computed: {
		...mapGetters(['isEmbedded']),
	},
};
</script>
